import * as API from "@/game-lol/apis/api.mjs";
import { getDefaultedFiltersForProBuilds } from "@/game-lol/utils/util.mjs";

function fetchData(params, searchParams) {
  const [tab] = params;
  const filters = getDefaultedFiltersForProBuilds(searchParams);

  const promises = [];

  const role = filters.role || filters.lane;

  switch (tab) {
    case "history":
    case undefined:
    default:
      if (!filters.team) delete filters.team;
      if (filters.team) filters.teamIds = [filters.team];

      promises.push(API.PROBUILDS.getProMatchHistory({ role }));

      break;
  }

  return Promise.all(promises);
}

export default fetchData;
